import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Header = () => {
  return (
    <header className="header-wrapper">
      <section className="header">
        <div className="container">
          <div className="header__inner">
            <div className="header__logo">
              <StaticQuery
                query={graphql`
                  query {
                    file(relativePath: { eq: "logo-riegos-azuer.jpg" }) {
                      childImageSharp {
                        fixed(fit: COVER, width: 180) {
                          ...GatsbyImageSharpFixed_withWebp_noBase64
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <Link to="/" className="js-tab-focus">
                    <Image
                      fixed={data.file.childImageSharp.fixed}
                      loading="auto"
                      fadeIn={false}
                    />
                  </Link>
                )}
              />
            </div>
            <nav className="header__nav" role="navigation" aria-label="Main">
              <ul className="header__list">
                <li className="header__item">
                  <Link
                    to="/about-us/historia"
                    className="header__link js-tab-focus"
                  >
                    Quiénes somos
                  </Link>
                </li>
                <li className="header__item">
                  <Link to="/contacto" className="header__link js-tab-focus">
                    Contacto
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </header>
  )
}

export default Header
