import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div className="padding-y-lg bg-gradient">
      <div className="container max-width-xs">
        <ul className="flex flex-wrap justify-around items-center gap-sm">
          <li>
            <Link className="color-black" to="/aviso-legal">
              Aviso Legal
            </Link>
          </li>
          <li>
            <Link className="color-black" to="/privacidad">
              Política de Privacidad
            </Link>
          </li>
          <li>
            <Link className="color-black" to="/cookies">
              Política de Cookies
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
